<template>
  <div class="_bg-default _100vh _80w">
    <div class="_100top _60-w pa-3">
      <v-card class="radius-card">
        <v-img src="../../assets/img/meptest.jpeg" height="120px"></v-img>
        <v-overlay :value="loading" color="white" opacity="1" absolute>
          <v-progress-circular
            color="grey"
            indeterminate
            size="35"
          ></v-progress-circular>
        </v-overlay>
        <div class="pa-3 d-flex" v-if="profile">
          <v-avatar color="white" size="150" class="min-img">
            <img
              :src="
                profile.photo_profile
                  ? `${env}/upload/photo_profile/${profile.id}/${profile.photo_profile}`
                  : avatar
              "
              class="img-fit"
              alt="asd"
            />
          </v-avatar>
          <div class="px-3">
            <h3>{{ profile.nama_lengkap }}</h3>
          </div>
        </div>
        <div class="pa-3" v-if="profile">
          <v-row>
            <v-col cols="12" md="12" v-if="fields">
              <p class="font-weight-bold small_txt ma-0">
                Bidang
              </p>
              <v-select
                v-if="fields"
                :options="fields.data"
                :reduce="(item) => item.id"
                @search="(query) => (searchFields = query)"
                v-model="profile.bidang_id"
                label="nama"
                placeholder="Bidang"
                class="white"
              ></v-select>
            </v-col>
            <v-col cols="12">
              <p class="font-weight-bold small_txt ma-0">
                Jenis Kelamin
              </p>
              <v-select
                :options="gender"
                :reduce="(item) => item.val"
                v-model="profile.jenis_kelamin"
                label="state"
                placeholder="Gender"
                class="white"
              ></v-select>
            </v-col>
          </v-row>
        </div>
        <div class="pa-3" v-if="listProvince">
          <p class="font-weight-bold small_txt ma-0">Location</p>
          <v-row>
            <!-- province -->
            <v-col cols="12" md="6">
              <v-select
                :options="listProvince"
                :reduce="(item) => item.id"
                v-model="location1.data_provinsi.id"
                @search="(query) => (searchProvince = query)"
                label="nama_provinsi"
                :appendToBody="true"
                placeholder="Province"
                class="white __pgUp"
                @input="getCity(location1.data_provinsi.id, 'core', 'select')"
              ></v-select>
            </v-col>
            <!-- city 1 -->
            <v-col cols="12" md="6">
              <v-select
                v-if="listCity1"
                :options="listCity1.data"
                :appendToBody="true"
                :reduce="(item) => item.id"
                v-model="location1.id"
                @search="(query) => (searchCity = query)"
                label="nama_kota"
                placeholder="City"
                class="white __pgUp"
              ></v-select>
              <div v-else>
                <v-progress-circular
                  indeterminate
                  color="primary"
                  class="mr-1"
                ></v-progress-circular
                >Memuat kota..
              </div>
            </v-col>
          </v-row>
          <div class="pt-5">
            <p class="font-weight-bold small_txt ma-0">Additional Location</p>
          </div>
          <v-row v-if="location2">
            <!-- profince -->
            <v-col cols="12" md="6">
              <v-select
                :options="listProvince"
                :reduce="(item) => item.id"
                v-model="location2.data_provinsi.id"
                @search="(query) => (searchProvince = query)"
                label="nama_provinsi"
                placeholder="Province"
                :appendToBody="true"
                class="white"
                @input="getCity(location2.data_provinsi.id, 'add', 'select')"
              ></v-select>
            </v-col>
            <!-- city 2 -->
            <v-col cols="12" md="6">
              <v-select
                v-if="listCity2"
                :options="listCity2.data"
                :reduce="(item) => item.id"
                v-model="location2.id"
                @search="(query) => (searchCity = query)"
                label="nama_kota"
                :appendToBody="true"
                placeholder="City"
                class="white"
              ></v-select>
              <div v-else>
                <v-progress-circular
                  indeterminate
                  color="primary"
                  class="mr-1"
                ></v-progress-circular
                >Memuat kota..
              </div>
            </v-col>
          </v-row>
          <v-row v-if="!location2">
            <v-col cols="12" md="6">
              <v-select
                :options="listProvince"
                :reduce="(item) => item.id"
                v-model="selectedProvince2"
                @search="(query) => (searchProvince = query)"
                label="nama_provinsi"
                placeholder="Province"
                class="white"
                @input="getCity(selectedProvince2, 'add')"
              ></v-select>
            </v-col>
            <v-col cols="12" md="6">
              <v-select
                v-if="listCity2"
                :options="listCity2.data"
                :reduce="(item) => item.id"
                v-model="selectedCity2"
                @search="(query) => (searchCity = query)"
                label="nama_kota"
                placeholder="City"
                class="white"
              ></v-select>
              <v-select v-else placeholder="City" class="white">
                <span slot="no-options" @click="$refs.select.open = false">
                  pilih provinsi terlebih dahulu
                </span>
              </v-select>
            </v-col>
          </v-row>
          <v-btn
            dark
            color="purple"
            v-if="!loading"
            @click="updateData"
            block
            class="mt-5"
            >Update</v-btn
          >
          <v-btn dark color="purple" v-else block class="mt-5"
            >Loading...</v-btn
          >
          <v-btn
            outlined
            color="purple"
            @click="$router.go(-1)"
            block
            class="mt-3"
            >Kembali</v-btn
          >
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import Swal from "sweetalert2";

export default {
  name: "adminUpdatePsikolog",
  components: {
    vSelect,
  },
  computed: {
    ...mapState({
      env: (state) => state.API_URL,
      fields: (state) => state.fields.fields,
      role: (state) => state.role,
      avatar: (state) => state.dummy,
    }),
  },
  data() {
    return {
      loading: false,
      profile: null,
      loadCard: true,
      listProvince: null,
      location1: null,
      location2: null,
      listCity1: null,
      listCity2: null,
      loadPost: false,
      searchProvince: "",
      selectedProvince2: null,
      selectedCity2: null,
      searchField: "",
      searchBiro: "",
      searchReligion: "",
      err: "",
      gender: [
        { state: "Laki-laki", val: "Laki-laki" },
        { state: "Perempuan", val: "Perempuan" },
      ],
    };
  },
  mounted() {
    this.fetch();
    this.$store.dispatch("fields/listFields");
  },
  methods: {
    fetch() {
      this.loadCard = true;
      let id = this.$route.params.id;
      this.$store
        .dispatch("viewPsycholog", id)
        .then((data) => {
          console.log(data);
          this.profile = data.data;
          let res = data.data;
          this.location1 = res.data_kota1;
          this.location2 = res.data_kota2;
          this.$store.dispatch("area/publicProvince").then((province) => {
            this.listProvince = province.data;
            this.getCity(res.data_kota1.provinsi_id, "core");
            this.getCity(res.data_kota2.provinsi_id, "add");
          });
          this.loadCard = false;
        })
        .catch((err) => {
          console.log(err);
          this.err = err;
          this.loadCard = false;
        });
    },
    getCity(id, type, from) {
      if (type == "core") {
        this.$store.dispatch("area/filterCity", id).then((data) => {
          this.listCity1 = data;
          if (from) {
            this.location1.id = data.data[0].id;
          }
        });
      } else {
        this.$store.dispatch("area/filterCity", id).then((data) => {
          this.listCity2 = data;
          if (from) {
            this.location2.id = data.data[0].id;
          }
        });
      }
    },
    updateData() {
      this.loading = true;
      let data = {
        bidang_id: this.profile.bidang_id,
        jenis_kelamin: this.profile.jenis_kelamin,
        kota1_id: this.location1.id,
        kota2_id: this.location2 ? this.location2.id : this.selectedCity2,
      };

      let id = this.profile.id;
      this.$store
        .dispatch("psycholog/updateProfile", { id: id, data: data })
        .then((data) => {
          console.log(data);
          this.$router.go(-1);
          this.loading = false;
          Swal.fire({
            icon: "success",
            title: data.message,
            showConfirmButton: false,
            position: "top",
            toast: true,
            timer: 3000,
          });
        })
        .catch((err) => {
          for (let i in err) {
            this.alert("error", `${err[i][0]}`);
          }
          this.loading = false;
        });
    },
    alert(type, title) {
      this.$notify({
        group: "validate",
        type: type,
        title: title,
        duration: 5000,
      });
    },
  },
};
</script>

<style scoped>
.__profile {
  display: flex;
}
._60-w {
  width: 60%;
}
._10w {
  width: 40%;
  /* padding-right: 20px; */
}
.min-img {
  margin-top: -120px;
  border: 2px white solid;
}
.line__height {
  line-height: 0.1;
}
.padd__to_bottom__ {
  height: 100px;
}
.left__top {
  margin-top: 90px;
}
</style>
